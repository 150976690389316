import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { isArray } from "lodash";
import H2 from "../htmlElements/h2";
import SearchBox from "./SearchBox";
import Facets from "./Facets";
import Button from "../htmlElements/button";

import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import {
  SearchDispatchContext,
  SearchStateContext,
} from "../../context/SearchContextProvider";
import { getProjectName } from "../../lib/functions";

const showFiltersOnMobileTextDefault = "Show filters";
const showFiltersOnMobileTextClose = `Show results`;

export default function LeftColumnContent() {
  const searchDispatch = useContext(SearchDispatchContext) || "";
  const searchState = useContext(SearchStateContext) || "";
  const globalDispatch = useContext(GlobalDispatchContext) || "";

  const projectName = getProjectName();

  const [showFiltersOnMobile, setShowFiltersOnMobile] = useState(false);
  const [showFiltersOnMobileText, setShowFiltersOnMobileText] = useState(
    showFiltersOnMobileTextDefault
  );

  const [recordType, setRecordType] = useState("all");

  const showFilters = () => {
    setShowFiltersOnMobile(!showFiltersOnMobile);
    setShowFiltersOnMobileText(
      showFiltersOnMobile
        ? showFiltersOnMobileTextDefault
        : showFiltersOnMobileTextClose
    );

    globalDispatch({
      type: "mobileSettings",
      fieldName: "mobileSearchFilterOpen",
      value: !showFiltersOnMobile,
    });
  };

  const recordTypeHandler = (type: string) => {
    setRecordType(type);

    searchDispatch({
      type:
        type === "withMaterials" ? "searchWithMaterials" : "searchAllMaterials",
    });
  };

  /**
   * Update the `withMaterials` or `all` filter on loading the page
   */
  useEffect(() => {
    if (isArray(searchState.searchData?.filters?.containsFiles)) {
      if (searchState.searchData?.filters?.containsFiles[0]?.value === "true") {
        setRecordType("withMaterials");
      } else {
        setRecordType("all");
      }
    }
  }, [searchState]);

  const bgClass = projectName === "oasis" ? "bg-white" : "bg-gray-light";

  return (
    <>
      <H2
        innerContent="Filter your results"
        additionalClasses="pb-5 hidden md:block"
      />

      <div className="w-full md:hidden fixed z-50 left-0 bottom-0 p-2 bg-white border-t border-gray-light">
        <Button
          innerContent={showFiltersOnMobileText}
          color="blue"
          additionalClasses="w-full"
          onClick={showFilters}
        />
      </div>

      <div
        className={clsx(
          showFiltersOnMobile ? "block" : "hidden",
          bgClass,
          "fixed z-40 top-0 left-0 w-full p-5 pb-16 h-screen mobileSearchFilterDiv", // <-- for mobile
          "md:block md:relative md:p-0 md:h-full" // <-- for desktop
        )}
      >
        <div className="text-2xl font-bold font-heading pb-5 md:hidden">
          Filter your results
        </div>

        <SearchBox />

        {projectName === "iris" ? (
          <div className="pb-3 mb-3 border-b border-b-gray-2 rounded-r-none">
            <input
              type="radio"
              name="recordType"
              value="all"
              checked={recordType === "all"}
              onChange={() => recordTypeHandler("all")}
            />{" "}
            All records |{" "}
            <input
              type="radio"
              name="recordType"
              value="withMaterials"
              checked={recordType === "withMaterials"}
              onChange={() => recordTypeHandler("withMaterials")}
            />{" "}
            Records with materials
          </div>
        ) : null}

        <Facets projectName={projectName} loading={false} />
      </div>
    </>
  );
}
